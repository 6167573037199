import { React, useState } from "react";
import "./App.css";
import Home from "./layout/home/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import Login from "./login/Login";
import Forgot from "./login/Forgot";
import Sinup from "./login/Sinup";
import Ap from "./curriculum/ap/Ap";
import All from "./curriculum/All";
import Payment from "./curriculum/payment/Payment";
import Key_concept from "./key-concept/Key_concept";
import Unit from "./key-concept/Unit";
import Question from "./key-concept/Question";
import Questionbank from "./questionbank/Question";
import Questionbankmain from "./questionbank/Questionbank";
import Question_unit from "./questionbank/Unit";
import Pastpaper_unit from "./pastpaper/Unit";
import Pastpaper_question from "./pastpaper/Question";
import Quiz from "./quiz/Quiz";
import Quiz_unit from "./quiz/Unit";
import Quiz_question from "./quiz/Question";
import Contactus from "./contactus/Contactus";
import Privacypolicy from "./Privacypolicy";
import Blog from "./blog/Blog";
import Blogsingle from "./blog/Blogsingle";
import Myprofile from "./myprofile/Myprofile";
import Result from "./quiz/Result";
import SimilarQuestion from "./questionbank/SimilarQuestion";

import Reset from "./login/Reset";
import { Switch, Route, Redirect } from "react-router-dom";
import { Pagenotfound } from "./404/Pagenotfound";
import Success from "./success/Success";
import Errorpayment from "./success/Errorpayment";
import Pastpaperexam from "./pastpaperexam/Pastpaperexam";
import ScrollToTop from "./ScrollToTop";

function App() {
  return (
    <ScrollToTop>
    <Switch>
      <>
        <Route exact path="/" component={Home} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/forgot" component={Forgot} />
        <Route exact path="/sinup" component={Sinup} />
        <Route exact path="/AP/:slug/" component={Ap} />
        <Route exact path="/All-Curriculum" component={All} />
        <Route exact path="/Payment" component={Payment} />
        <Route exact path="/Key-Concept/:slug/" component={Key_concept} />
        <Route exact path="/Unit/:slug/" component={Unit} />
        <Route exact path="/Key-Concept-Question/:slug/" component={Question} />
        <Route
          exact
          path="/Question-Bank/:slug/"
          component={Questionbankmain}
        />
        <Route
          exact
          path="/Question-Bank-Question/:slug/"
          component={Questionbank}
        />
        <Route
          exact
          path="/Question-Bank-Unit/:slug/"
          component={Question_unit}
        />
        {/* <Route path="/Past-Paper" element={<Pastpaper/>}></Route> */}
        <Route
          exact
          path="/Past-Paper-Question/:slug/"
          component={Pastpaper_question}
        />
        <Route
          exact
          path="/Past-Paper-Unit/:slug/"
          component={Pastpaper_unit}
        />
        <Route
          exact
          path="/Similar-Question/:slug/"
          component={SimilarQuestion}
        />
        <Route exact path="/Quiz-Question/:slug/" component={Quiz_question} />
        <Route exact path="/Quiz-Unit/:slug/" component={Quiz_unit} />
        <Route exact path="/Quiz/:slug/" component={Quiz} />
        <Route exact path="/Contact-us" component={Contactus} />
        <Route exact path="/Privacy-Policy" component={Privacypolicy} />
        <Route exact path="/Blog" component={Blog} />
        <Route exact path="/Blog-single/:id/" component={Blogsingle} />
        <Route exact path="/My-Profile" component={Myprofile} />
        <Route exact path="/Result" component={Result} />
        <Route exact path="/404" component={Pagenotfound} />

        <Route exact path="/reset" component={Reset} />
        <Route exact path="/Success" component={Success} />
        <Route exact path="/Error" component={Errorpayment} />
        <Route exact path="/Past-Paper-Exam" component={Pastpaperexam} />
      </>
    </Switch>
    </ScrollToTop>
  );
}

export default App;
