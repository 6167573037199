import React from 'react';
import './ApCourseCard.css';
import Col from 'react-bootstrap/Col';

import Key from '../../img/icon/Molecule_light_w.png';
import Question from '../../img/icon/Quiz_w.png';
import Past from '../../img/icon/Book_open_alt_light_w.png';
import Quiz from '../../img/icon/Book_w.png';

import { NavLink } from 'react-router-dom';
import { useGetAllcoursesQuery } from '../../services/aplms';
import Loader from '../../loader/Loader';
import Error from '../../errordata/Error';

const Coursecard = (props) => {
  window.scrollTo(0, 0);
  const slug = props.propsData;
  const couresdata = useGetAllcoursesQuery(slug);

  if (couresdata.isLoading)
    return (
      <div>
        <Loader />
      </div>
    );

  if (couresdata.data[0] == null || couresdata.isError)
    return (
      <div>
        <Error />
      </div>
    );

  // Define the color themes
  const colorThemes = ['purple', 'blue', 'green', 'red'];

  return (
    <>
      {couresdata.data.map((coures, index) => (
        <Col sm={3} key={coures.id}>
          {/* Assign a theme class based on the index */}
          <div className={`apcoursecard-box ${colorThemes[index % colorThemes.length]}-theme`}>
            <h4>{coures.name}</h4>
            <ul className="apcoursecard-box-content">
              <li className="apcoursecard-box-content-button">
                <NavLink
                  to={'/Key-Concept/' + coures.slug}
                  className="apcoursecard-navlink">
                  <span className="apcoursecard-icon-wrapper">
                    <img src={Key} alt="Key Concept Icon" />
                  </span>
                  Key Concepts
                </NavLink>
              </li>
              <li className="apcoursecard-box-content-button">
                <NavLink
                  to={'/Question-Bank/' + coures.slug}
                  className="apcoursecard-navlink">
                  <span className="apcoursecard-icon-wrapper">
                    <img src={Question} alt="Question Bank Icon" />
                  </span>
                  Question Bank
                </NavLink>
              </li>
              <li className="apcoursecard-box-content-button">
                <NavLink
                  to={'/Past-Paper-Unit/' + coures.slug}
                  className="apcoursecard-navlink">
                  <span className="apcoursecard-icon-wrapper">
                    <img src={Past} alt="Past Papers Icon" />
                  </span>
                  Past Papers
                </NavLink>
              </li>
              <li className="apcoursecard-box-content-button">
                <NavLink
                  to={'/Quiz/' + coures.slug}
                  className="apcoursecard-navlink">
                  <span className="apcoursecard-icon-wrapper">
                    <img src={Quiz} alt="Quiz Icon" />
                  </span>
                  Quizzes
                </NavLink>
              </li>
            </ul>
          </div>
        </Col>
      ))}
    </>
  );
};

export default Coursecard;
