import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { NavLink } from 'react-router-dom';
import Right_arror from '../img/icon/right-arror.png';
import Right_arror_w from '../img/icon/Expand_right_light_w.png';
import { useGetAlltopicQuery } from '../services/aplms';
import Loader from '../loader/Loader';
import Error from '../errordata/Error';
const KeyTopic = (props) => {
  const slug = props.propsdata;
  const topicdata = useGetAlltopicQuery(slug);
  if (topicdata.isLoading)
    return (
      <div>
        <Loader />
      </div>
    );
  if (topicdata.data[0] == null)
    return (
      <div>
        <Error />
      </div>
    );

  if (topicdata.isError)
    return <h1>An error occured {topicdata.error.error}</h1>;
  return (
    <>
      <Row>
        {topicdata.data.map((topic) => {
          return (
            <Col sm={4}>
              <NavLink
                to={'/Unit/' + slug}
                className="qu-box">
                <h4>{topic.name}</h4>
                <div className="right-arror-key">
                  <img
                    src={Right_arror}
                    className="d"
                  />
                  <img src={Right_arror_w} />
                </div>
              </NavLink>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default KeyTopic;
