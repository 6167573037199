import React, { useState } from 'react';
import './Course.css';
import { FaChevronRight } from 'react-icons/fa';
import Key from '../img/icon/Molecule_light.png';
import Question from '../img/icon/Quiz.png';
import Past from '../img/icon/Book_open_alt_light.png';
import Quiz from '../img/icon/Book.png';
import { useGetAllcoursesQuery } from '../services/aplms';
import Loader from '../loader/Loader';
import Error from '../errordata/Error';
import { NavLink } from 'react-router-dom';

const Course = (props) => {
  const slug = props.propsData;
  const couresdata = useGetAllcoursesQuery(slug);
  const [visibleCourses, setVisibleCourses] = useState({});

  const hexToRgba = (hex, alpha) => {
    const hexClean = hex.replace('#', '');
    const bigint = parseInt(hexClean, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  if (couresdata.isLoading) return <Loader />;
  if (!couresdata.data || couresdata.data[0] == null) return null;
  if (couresdata.isError) return <Error />;

  const colors = ['#6708B1', '#275285', '#497B22', '#970000']; // Define the color sequence

  return (
    <ul className="cur-box-content">
      {couresdata.data.map((course, index) => {
        const isVisible = visibleCourses[course.slug];
        const color = colors[index % colors.length]; // Get color based on index

        // Define the button data
        const buttonData = [
          {
            to: `/Key-Concept/${course.slug}`,
            imgSrc: Key,
            alt: 'Key Concepts',
            text: 'Key Concepts',
          },
          {
            to: `/Question-Bank/${course.slug}`,
            imgSrc: Question,
            alt: 'Question Bank',
            text: 'Question Bank',
          },
          {
            to: `/Past-Paper-Unit/${course.slug}`,
            imgSrc: Past,
            alt: 'Past Papers',
            text: 'Past Papers',
          },
          {
            to: `/Quiz/${course.slug}`,
            imgSrc: Quiz,
            alt: 'Quizzes',
            text: 'Quizzes',
          },
          // Add more buttons here if needed
        ];

        // Define opacity parameters
        const minOpacity = 0.2; // Starting opacity
        const maxOpacity = 0.35; // Ending opacity
        const totalButtons = buttonData.length;
        const opacityIncrement =
          totalButtons > 1
            ? (maxOpacity - minOpacity) / (totalButtons - 1)
            : 0;

        return (
          <li className="cur-box-content-button" key={course.slug}>
            <button
              className="course-name-button"
              onClick={() =>
                setVisibleCourses((prevState) => ({
                  ...prevState,
                  [course.slug]: !prevState[course.slug],
                }))
              }
              style={{ color }} // Apply the color to the button text
            >
              {course.name}
              <FaChevronRight
                className={`arrow-icon ${isVisible ? 'open' : ''}`}
                aria-hidden="true"
              />
            </button>
            {isVisible && (
              <div className="icon-img">
                {buttonData.map((btn, idx) => {
                  // Calculate the opacity for this button
                  const opacity = minOpacity + opacityIncrement * idx;
                  // Convert the parent color to RGBA with the calculated opacity
                  const buttonColorRgba = hexToRgba(color, opacity);

                  return (
                    <NavLink
                      to={btn.to}
                      className="glass-button"
                      key={idx}
                      style={{
                        background: buttonColorRgba,
                      }}
                    >
                      <img src={btn.imgSrc} className="d" alt={btn.alt} />
                      {btn.text}
                    </NavLink>
                  );
                })}
              </div>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default Course;
