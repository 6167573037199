import React, { useState, useEffect } from "react";
import "./SimilarQuestion.css"; // Import the specific CSS file
import { Link, useHistory, useParams } from "react-router-dom";
import Header from "../layout/header/Header";
import Footer from "../layout/footer/Footer";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import qu_top from "../img/icon/Quiz-bank-b.png";
import qu_top_w from "../img/icon/Quiz-bank.png";
import EasyIcon from "../img/icon/EasyIcon.png";
import MediumIcon from "../img/icon/MediumIcon.png";
import HardIcon from "../img/icon/HardIcon.png";
import Loader from "../loader/Loader";
import HTMLReactParser from "html-react-parser";
import axios from "axios";
import BGRight2 from "../img/Bg Right 2.png";

const SimilarQuestion = () => {
  const history = useHistory();
  const { slug } = useParams();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState(null);
  const [showFormulaModal, setShowFormulaModal] = useState(false);
  const [currentFormulaImage, setCurrentFormulaImage] = useState(null);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [currentVideoURL, setCurrentVideoURL] = useState(null);
  const [showMarkSchemeModal, setShowMarkSchemeModal] = useState(false);
  const [currentMarkSchemeImage, setCurrentMarkSchemeImage] = useState(null);

  const handleOpenMarkSchemeModal = (htmlString) => {
    const imageUrls = extractImageUrls(htmlString);
    if (imageUrls.length > 0) {
      setCurrentMarkSchemeImage(imageUrls);
      setShowMarkSchemeModal(true);
    } else {
      setCurrentMarkSchemeImage(null);
      setShowMarkSchemeModal(false);
    }
  };

  const handleCloseMarkSchemeModal = () => {
    setShowMarkSchemeModal(false);
    setCurrentMarkSchemeImage(null);
  };

  const handleOpenVideoModal = (videoURL) => {
    setCurrentVideoURL(videoURL);
    setShowVideoModal(true);
  };

  const handleCloseVideoModal = () => {
    setShowVideoModal(false);
    setCurrentVideoURL(null);
  };

  const handleOpenFormulaModal = (formulaImage) => {
    setCurrentFormulaImage(formulaImage);
    setShowFormulaModal(true);
  };

  const extractImageUrls = (htmlString) => {
    const imgTags = htmlString.match(/<img [^>]*src="[^"]*"[^>]*>/gm);
    if (!imgTags) return [];

    const srcRegex = /src="([^"]*)"/;
    return imgTags
      .map((imgTag) => {
        const match = imgTag.match(srcRegex);
        return match ? match[1] : null;
      })
      .filter(Boolean);
  };

  const handleCloseFormulaModal = () => {
    setShowFormulaModal(false);
    setCurrentFormulaImage(null);
  };

  const fetchQuestions = async () => {
    try {
      const response = await axios.get(
        `https://num8ers.cloud/api/similarquestion/${slug}`
      );
      console.log("Fetched Similar Questions:", response.data);
      setQuestions(response.data);
    } catch (err) {
      console.error("Error fetching similar questions:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, [slug]);

  if (loading)
    return (
      <div>
        <Loader />
      </div>
    );
  if (error)
    return <h1>An error occurred while fetching similar questions.</h1>;

  return (
    <div className="similar-question-page">
      <div className="top-header-quetion">
        <Header />
        <section className="ap">
          <Container>
            <Row className="pb-120">
              <Col sm={12}>
                <div className="top-nav">
                  <Breadcrumb>
                    <Breadcrumb.Item>
                      <Link to="/">Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <span onClick={() => history.goBack()}>
                        Back to Question
                      </span>
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </Col>
              <Col sm={12}>
                <div className="ap-heading">
                <h2>{questions[0].couresname} - Question Bank</h2>
                  <Row>
                    <Col sm={8}>
                      <p>
                        Here are some questions similar to the one you selected.
                      </p>
                    </Col>
                    <Col sm={4}>
                      <div className="key-concept-top-right text-center">
                        <img src={qu_top} className="d" alt="Icon" />
                        <img src={qu_top_w} alt="Icon" />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <section className="unit-page pt-60 pb-120">
        <img src={BGRight2} alt="Splatter" className="splatter-image-right-2" />
        <Container>
          <Row>
            {/* Main Content */}
            <Col sm={{ span: 10, offset: 1 }}>
              {questions && questions.length > 0 ? (
                questions.map((question, index) => (
                  <div
                    key={index}
                    className="questions-container"
                    id={`question-${index}`}
                  >
                    <div className="title-container">
                      <h3 className="h3-title">Question {index + 1}</h3>
                      {question.status === "Easy" && (
                        <img
                          src={EasyIcon}
                          alt="Easy Icon"
                          className="status-icon"
                        />
                      )}
                      {question.status === "Medium" && (
                        <img
                          src={MediumIcon}
                          alt="Medium Icon"
                          className="status-icon"
                        />
                      )}
                      {question.status === "Hard" && (
                        <img
                          src={HardIcon}
                          alt="Hard Icon"
                          className="status-icon"
                        />
                      )}
                    </div>

                    {question.formula && (
                      <Button
                        variant="link"
                        onClick={() => handleOpenFormulaModal(question.formula)}
                        className="formula-button"
                      >
                        Formulas
                      </Button>
                    )}

                    <div className="question-photo">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: question.description.replace(
                            /<img /g,
                            '<img style="max-width: 100%; height: auto; margin-top: 1vh; margin-bottom: 1vh; border-radius: 20px;" '
                          ),
                        }}
                      />
                    </div>

                    {/* Buttons for Mark Scheme and Video Answer */}
                    <div className="additional-buttons">
                      <Button
                        variant="link"
                        onClick={() =>
                          handleOpenMarkSchemeModal(question.photo)
                        }
                        className="additional-button"
                      >
                        Mark Scheme
                      </Button>
                    </div>
                  </div>
                ))
              ) : (
                <p>No similar questions found.</p>
              )}
            </Col>
          </Row>
        </Container>
      </section>
      {/* Modal for viewing formula */}
      <Modal
        show={showFormulaModal}
        onHide={handleCloseFormulaModal}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Formula</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentFormulaImage && (
            <iframe
              src={currentFormulaImage}
              style={{ width: "100%", height: "600px" }}
              title="Formula PDF"
            />
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={showVideoModal}
        onHide={handleCloseVideoModal}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Video Answer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentVideoURL ? (
            <div className="video-container">
              <iframe
                src={currentVideoURL}
                style={{ width: "100%", height: "400px" }}
                title="Video Answer"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          ) : (
            <p>No video available.</p>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={showMarkSchemeModal}
        onHide={handleCloseMarkSchemeModal}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Mark Scheme</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentMarkSchemeImage && currentMarkSchemeImage.length > 0 ? (
            currentMarkSchemeImage.map((src, index) => (
              <img
                key={index}
                src={src}
                alt={`Mark Scheme ${index + 1}`}
                style={{ width: "100%", height: "auto", marginBottom: "10px" }}
              />
            ))
          ) : (
            <p>No Mark Scheme available.</p>
          )}
        </Modal.Body>
      </Modal>

      <Footer />
    </div>
  );
};

export default SimilarQuestion;
