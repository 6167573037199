import React from 'react';
import './popup.css';
import HTMLReactParser from 'html-react-parser';

const Quizpopup = (props) => {
  return (
    <>
      <div className="popup-overlay">
        <div className="popup-content">
          <div className="popup-header">
            <button className="popup-close-button" onClick={props.hide}>
              &times;
            </button>
          </div>
          <div className="popup-body">
            <p className="popup-text">{HTMLReactParser(props.photo)}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Quizpopup;
